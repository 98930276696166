import React from "react";
import { Link } from "gatsby";
import "@styles/PostFooter.css";
import { graphql, useStaticQuery } from "gatsby";
import dayjs from "dayjs";

const PostFooter = () => {
    const {
        site: { buildTime },
    } = useStaticQuery(graphql`
        query {
            site {
                buildTime
            }
        }
    `);

    return (
        <>
            <div className={`postfooter background font-montserrat`}>
                <div className="container lg:px-0">
                    <div className="py-5 items-center md:justify-between md:flex">
                        <div className="text-center text-xs opacity-75 md:text-left">
                            © iOpos Academia {new Date().getFullYear()}. Todos los derechos
                            reservados.
                        </div>
                        <div className="text-center mt-2 text-xs md:mt-0 md:text-left space-y-1">
                            <div>
                                <Link
                                    to="/preguntas-frecuentes/"
                                    activeClassName="active"
                                    className="link"
                                    title="Preguntas frecuentes"
                                >
                                    Faqs
                                </Link>
                                &nbsp;·&nbsp;
                                <Link
                                    to="/privacidad/aviso-legal/"
                                    activeClassName="active"
                                    className="link"
                                >
                                    Aviso legal y política de privacidad
                                </Link>
                                &nbsp;·&nbsp;
                                {/*&nbsp;·&nbsp;
                                <Link
                                    to="/privacidad/politica-de-privacidad/"
                                    activeClassName="active"
                                    className="link"
                                >
                                    Política de privacidad
                                </Link>
                                &nbsp;·&nbsp;*/}
                                <Link
                                    to="/privacidad/politica-de-cookies/"
                                    activeClassName="active"
                                    className="link"
                                >
                                    Política de cookies
                                </Link>
                                &nbsp;·&nbsp;
                                <Link to="/contacto/" activeClassName="active" className="link">
                                    Contacto
                                </Link>
                            </div>

                            <div
                                style={{ fontSize: "11px" }}
                                className="md:text-right text-xs text-white text-opacity-50 font-montserrat"
                            >
                                Actualización:{" "}
                                <span className="font-semibold">
                                    {dayjs(buildTime).format("DD/MM/YYYY HH:mm")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default PostFooter;
