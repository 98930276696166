import React, { useState, useEffect } from "react";
// import { FaCross, FaWindowClose } from "react-icons/fa";

const COOKIE_KEY = "cookiesAccepted";
// const EXPIRATION_DAYS = 7;

const Component = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const storedValue = localStorage.getItem(COOKIE_KEY);
        if (!storedValue) {
            setIsVisible(true);
        }
    }, []);

    /*const acceptCookies = () => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + EXPIRATION_DAYS);
        localStorage.setItem(COOKIE_KEY, expirationDate.toISOString());
        setIsVisible(false);
    };*/

    if (!isVisible) return null;

    return (
        <div className="fixed left-0 bottom-0 z-100 font-poppins">
            {/*<div className="mx-auto max-w-4xl rounded-lg bg-gray-900 bg-opacity-75 p-4 shadow-lg backdrop-blur md:p-6 ml-5 mb-5">
                <div className="flex flex-col gap-4">
                    <div className="flex-1 text-xs text-white">
                        <p className="mb-2">
                            Este portal web únicamente utiliza cookies propias con finalidad
                            técnica, no recaba ni cede datos de carácter personal de los usuarios
                            sin su conocimiento.
                        </p>
                        <p>
                            Sin embargo, contiene enlaces a sitios web de terceros con políticas de
                            privacidad ajenas a esta empresa que usted podrá decidir si acepta o no
                            cuando acceda a ellos.
                        </p>
                    </div>
                    <div className="flex flex-col gap-2 sm:flex-row md:flex-col lg:flex-row">
                        <button
                            variant="outline"
                            className="border-white text-white hover:bg-white hover:text-gray-900"
                            onClick={() => setIsVisible(false)}
                        >
                            Más info
                        </button>
                        <button
                            className="bg-white text-gray-900 hover:bg-gray-100"
                            onClick={() => acceptCookies()}
                        >
                            Aceptar
                        </button>
                    </div>

                    <div className="absolute right-0 top-0 p-2">
                        <button
                            onClick={() => setIsVisible(false)}
                            className="rounded-full p-1 text-white hover:bg-white/10"
                        >
                            <FaWindowClose className="h-4 w-4" />
                        </button>
                    </div>
                </div>
            </div>*/}
        </div>
    );
};

export default Component;
