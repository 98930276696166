import React from "react";
import { Link } from "gatsby";
import {
    FaMobileAlt,
    FaEnvelopeOpen,
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaWhatsapp,
    FaGlobe,
    FaYoutube,
    FaLocationArrow,
} from "react-icons/fa";
import Logo from "@components/common/Logo";
import "@styles/Footer.css";

const Footer = () => {
    return (
        <>
            <div className={`footer background font-montserrat`}>
                <div className="container lg:px-0">
                    <div className="py-10 flex flex-col md:flex-row gap-10">
                        <div className="flex md:pr-20">
                            <div className="mx-auto md:mx-0">
                                {/** Logo */}
                                <Link to="/">
                                    <Logo
                                        className="duration-300 w-20 md:w-32 mx-auto md:mx-0"
                                        alt="iopos logo"
                                    />
                                </Link>

                                <p className="mt-3 text-xs text-center md:text-justify">
                                    Descubre las mejores app's, libros, podcast, cursos online y
                                    cursos presenciales para prepararte con la máxima calidad y
                                    garantía de éxito tu oposición a Mossos d'Esquadra, Guàrdia
                                    Urbana de Barcelona, Policía Municipal de Madrid, Policías
                                    Locales, Guardia Civil, Policía Nacional, Sanitarios, Justicia y
                                    Administrativo en tiempo récord. ¡Prepárate de manera eficaz y
                                    eficiente para alcanzar tus metas en menos de un año!
                                </p>

                                {/** Iconos Sociales */}
                                <div className="mt-2 flex justify-center md:justify-between lg:justify-start ">
                                    <a
                                        target="_blank"
                                        aria-label="Facebook"
                                        rel="noopener noreferrer"
                                        href="https://www.facebook.com/iopos"
                                        className="icon-link mr-5"
                                    >
                                        <FaFacebook
                                            className="inline-block h-5 w-5"
                                            style={{ verticalAlign: "middle" }}
                                        />
                                    </a>

                                    <a
                                        target="_blank"
                                        aria-label="Instagram"
                                        rel="noopener noreferrer"
                                        href="https://www.instagram.com/iopos.es"
                                        className="icon-link mr-5"
                                    >
                                        <FaInstagram
                                            className="inline-block h-5 w-5"
                                            style={{ verticalAlign: "middle" }}
                                        />
                                    </a>

                                    <a
                                        target="_blank"
                                        aria-label="LinkedIn"
                                        rel="noopener noreferrer"
                                        href="https://www.linkedin.com/company/iopos"
                                        className="icon-link mr-5"
                                    >
                                        <FaLinkedin
                                            className="inline-block h-5 w-5"
                                            style={{ verticalAlign: "middle" }}
                                        />
                                    </a>

                                    <a
                                        target="_blank"
                                        aria-label="WhatsApp"
                                        rel="noopener noreferrer"
                                        href="https://wa.me/34644507478"
                                        className="icon-link mr-5"
                                    >
                                        <FaWhatsapp
                                            className="inline-block h-5 w-5 "
                                            style={{ verticalAlign: "middle" }}
                                        />
                                    </a>

                                    <a
                                        target="_blank"
                                        aria-label="WhatsApp"
                                        rel="noopener noreferrer"
                                        href="https://www.youtube.com/channel/UCbjYROOdRtc_y1VEUeCiFBQ"
                                        className={`icon-link`}
                                    >
                                        <FaYoutube
                                            className="inline-block h-5 w-5"
                                            style={{ verticalAlign: "middle" }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 flex-shrink-0 flex md:flex-none md:mt-0 text-sm">
                            <div className="mx-auto md:mx-0 w-full">
                                <h3 className="h3 text-center md:text-left">¿Cómo contactarnos?</h3>

                                <ul className="mt-5 px-2 text-xs">
                                    <li className="mt-2 duration-300">
                                        <a href="https://wa.me/34644507478" className="inline link">
                                            <FaMobileAlt
                                                className="inline mr-3 "
                                                style={{
                                                    verticalAlign: "middle",
                                                }}
                                            />
                                            (+34) 644 507 478
                                        </a>
                                    </li>
                                    <li className="mt-2 duration-300">
                                        <a href="mailto:info@iopos.academy" className="inline link">
                                            <FaEnvelopeOpen
                                                className="inline mr-3 "
                                                style={{
                                                    verticalAlign: "middle",
                                                }}
                                            />
                                            info@iopos.academy
                                        </a>
                                    </li>
                                    <li className="mt-2 duration-300">
                                        <a href="https://www.iopos.es" className="inline link">
                                            <FaGlobe
                                                className="inline mr-3"
                                                style={{
                                                    verticalAlign: "middle",
                                                }}
                                            />
                                            https://www.iopos.academy
                                        </a>
                                    </li>
                                    <li className="mt-2 duration-300">
                                        <FaLocationArrow className="inline mr-3" /> C/ Martí
                                        Codolar, núm. 18
                                    </li>
                                    <li className="mt-2 duration-300">
                                        <FaLocationArrow className="inline mr-3" /> 08902 Hospitalet
                                        de Llobregat, Barcelona
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Footer;
